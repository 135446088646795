<template>
  <div>
    <section id="card-images">
      <b-media vertical-align="center">
        <template #aside>
          <b-img
            :src="'https://content.brimecdn.com/cdn-cgi/image/quality=100/'+options.category.cover.replace('images.igdb.com', 'images.igdb.com').replace('t_1080p', 't_cover_big')"
            blank-color="#ccc"
            width="150"
            alt="placeholder"
          />
        </template>
        <h1 class="mt-1">
          {{ options.category.name }}
        </h1>
        <b-card-text>
          <p style="max-width: 700px !important">
            {{ options.category.summary }}
          </p>
        </b-card-text>
      </b-media><br>
      <div v-if="empty">
        <h3>No streams currently live for this category.</h3><br>
        <router-link :to="'/categories'">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.clipModal
            size="sm"
            variant="primary"
            class="float-left clipButton"
          >
            <feather-icon
              icon="ArrowLeftIcon"
            /><span class="clipButtonText"> Return to Categories </span>
          </b-button>
        </router-link>
      </div>
      <div v-if="invalid">
        <h3>You Pepega. That Category Doesn't Exist.</h3><br>
        <router-link :to="'/categories'">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.clipModal
            size="sm"
            variant="primary"
            class="float-left clipButton"
          >
            <feather-icon
              icon="ArrowLeftIcon"
            /><span class="clipButtonText"> Return to Categories </span>
          </b-button>
        </router-link>
      </div>
    </section>
    <section id="card-images">
      <b-row>
        <b-col
          v-for="item in options.streams"
          :key="item.channel"
          md="5"
          lg="4"
          xl="3"
        >
          <router-link :to="'/'+item.channel">
            <b-card
              :img-src="`https://thumbnails.brimecdn.com/live/${item.channelId}${suffix}`"
              border-variant=""
              class="mb-3"
            ><span
               id="streamTitle"
             ><b-avatar
                size="45"
                :src="`https://content.brimecdn.com/cdn-cgi/image/width=100,quality=100/brime/user/${item.broadcastingUser._id}/avatar`"
                variant="light-primary"
                class="badge-minimal mr-1"
              /><span
                id="title"
                style="padding-left: 50px; margin-top: -40px; color: #FFF"
              >{{ item.title }}</span>
               <span
                 id="stream"
                 style="padding-left: 55px;"
               >
                 <strong>{{ item.channel }}</strong><br>
                 <span
                   id="category"
                   style="color: rgba(255, 255, 255, 0.6)"
                 >
                   {{ item.category.name }}</span>
               </span></span>
              <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
              <div class="live__pill">
                LIVE
              </div>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BMedia, BCardText, BImg, BAvatar,
} from 'bootstrap-vue'

import Vue from 'vue'

Vue.filter('capitalize', string => {
  const capitalFirst = string.toUpperCase()
  return capitalFirst
})
const prefix = 'https://edge.brimelive.com/live/'
const suffix = '/thumbnail.jpg'
export default {
  components: {
    BCardText,
    BMedia,
    BImg,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
  },
  props: {
    slug: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      empty: false,
      invalid: false,
      options: { category: { name: '' } },
      test: {},
      prefix,
      suffix,
    }
  },
  async mounted() {
    try {
      this.options = await window.brime.getLiveByCategory(this.slug)
      document.title = `${this.options.category.name}- Brime`
      if (this.options.streams.length === 0) {
        this.empty = true
      }
    } catch (error) {
      this.invalid = true
    }
  },
}
</script>
<style scoped>
/* Scrollbar styles */
.dark-layout .card {
  box-shadow: unset;
 box-shadow: 0px 5px 10px 0 rgb(12 20 39 / 48%);
}
body {
  overflow: hidden; /* Hide scrollbars */
}
#style-4::-webkit-scrollbar-track
{
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}
.card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 0px 20px rgba(115,103,240,1);
}
.card:hover img {
  filter: saturate(1.2)
}

.card img {
  filter: saturate(1);
  transition: all 0.2s;
}
.live__pill {
  position: absolute;
  letter-spacing: 0.35px;
  left: 12px;
  top: 12px;
  padding: 2px 6px;
  background: #e91916;
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
#style-4::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 2px solid #555555;
}

.card {
  height: calc(100% - 3rem);
  display: flex;
  background-color: unset;
}

.card-body {
  padding: .75rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#category {
  margin-top: 2px;
  text-align: left;
  display: block;
}
#title {
  margin-left: 5px;
  color: #b4b7bd;
  margin-top: auto;
  text-align: left;
  font-size: 15px;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamtitle {
  font-size: 14px;
  line-height: 18px;
  vertical-align: center;
}
#stream {
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
  height: 100%;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamTitle {
  color: #7367f0;
  font-size: 14px;
  display: block;
  text-align: left;
  padding-top: 2px;
}
.badge-minimal{
  transform: translateY(11px);
}
</style>
